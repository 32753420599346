<template>
  <div class="sidebar grid gtr-auto-1-auto">
    <!-- Reload agree -->
    <ModalEmpty v-if="isOpenReload" @close="isOpenReload = false">
      <form id="form-reload" class="modal-reload grid ggap-20 p-30" @submit.prevent="reloadAgreement">
        <h1 class="title">Опишите причину перезапуска согласования</h1>
        <FormTextarea label="" placeholder="Описание причины" rows="5" required v-model="formData.comment" />
        <div class="flex ai-c jc-sb">
          <button class="btn cancel" type="button" @click.prevent="isOpenReload = false">Отмена</button>
          <button class="btn primary" type="submit">Перезапустить согласование</button>
        </div>
      </form>
    </ModalEmpty>

    <!-- Head -->
    <div class="sidebar__head flex ai-c jc-sb ggap-30 pl-20 pr-20">
      <TabSwipe :items="tabs" v-model="currentTab" class="full no-border">
        <template #item="{ item }">
          <div class="flex ai-c ggap-5">
            {{ item.text }}
            <small v-if="item.count" class="marker">
              {{ item.count }}
            </small>
          </div>
        </template>
      </TabSwipe>

      <!-- History -->
      <div v-if="currentTab === 'history'" class="flex ai-c ggap-10">
        <div class="sidebar__head-search form-item sm grid pos-r" v-tippy="'Поиск по тексту сообщения'">
          <label for="message-search" class="sidebar__head-search-label flex ai-c jc-c pos-a">
            <BaseIcon class="ic-16 primary" icon="search" />
          </label>
          <input
            type="search"
            name="message-search"
            id="message-search"
            class="form-item__input pl-30 pr-5"
            placeholder="Поиск..."
            autocomplete="off"
            v-model="searchText"
          />
        </div>

        <BaseDropDown
          direction="rtl"
          btn-class="btn grey sm pl-10 pr-10"
          btn-active-class="active"
          ref="filterDropDown"
        >
          <template #btn>
            <div class="grid gtc-auto-1 ggap-5 ai-c" v-tippy="'Фильтр: ' + currentFilterItem.text">
              <BaseIcon class="ic-16 primary" icon="filter" />
              <div class="cut">{{ currentFilterItem.text }}</div>
            </div>
          </template>
          <template #body>
            <div class="box p-5 grid ggap-5" @click.stop>
              <button
                v-for="filter of optionsFilter"
                :key="filter.value"
                class="btn transparent-grey w-100 sm"
                :class="{ active: currentFilter === filter.value }"
                @click.stop="setFilter(filter)"
              >
                <div class="fs-18">
                  {{ filter.icon }}
                </div>
                {{ filter.text }}
              </button>
            </div>
          </template>
        </BaseDropDown>
      </div>

      <!-- Agreement -->
      <div v-else class="flex ai-c ggap-10">
        <button
          type="button"
          v-if="order.step > 1"
          @click.prevent.stop="isAll = !isAll"
          class="btn transparent-grey sm pl-10"
        >
          <FormFakeSwitch :isTrue="isAll" />
          Все этапы
        </button>
        <button
          v-if="isResponsible || isSuperLawyer"
          type="button"
          class="btn primary-light sm pl-10 pr-10"
          v-tippy="'Перезапустить согласование'"
          :disabled="order.status.uid !== 'in-approval'"
          @click.prevent="isOpenReload = order.status.uid !== 'in-approval' ? false : !isOpenReload"
        >
          <BaseIcon class="ic-20 primary" icon="history" /> Пересогласовать
        </button>
      </div>
    </div>

    <!-- Body -->
    <component
      :is="currentComponent"
      :isHasEdit="isHasEdit"
      :searchText="searchText"
      :currentFilter="currentFilter"
      :isAll="isAll"
      :order="order"
      ref="sidebarHistory"
    />
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import {
  defineEmits,
  defineProps,
  toRefs,
  ref,
  watch,
  computed,
  defineAsyncComponent,
  defineExpose,
  reactive
} from 'vue'
import {
  ModalEmpty,
  BaseIcon,
  BaseDropDown,
  FormTextarea,
  // FormInput,
  // FormSelect,
  FormFakeSwitch,
  TabSwipe
} from '@/components'
import { isInvalidForm } from '@/plugins'
import agreements from '@/api/modules/agreements'

// Emits
const emits = defineEmits(['globaLoad'])

// Props
const props = defineProps(['order'])

// Data
const { order } = toRefs(props)
const store = useStore()
const sidebarHistory = ref()
const currentTab = ref('history')
const tabs = [
  {
    text: 'История',
    value: 'history',
    count: 0
  },
  {
    text: 'Согласование',
    value: 'agreement',
    count: 0
  }
]
const searchText = ref('')
const currentFilterItem = ref({
  icon: '💬',
  text: 'Любые сообщения',
  value: 'docs,comment'
})
const filterDropDown = ref()
const currentFilter = ref('docs,comment')
const optionsFilter = ref([
  {
    icon: '📚',
    text: 'Все записи',
    value: ''
  },
  {
    icon: '💬',
    text: 'Любые сообщения',
    value: 'docs,comment'
  },
  {
    icon: '📎',
    text: 'Только сообщения с файлами',
    value: 'docs'
  },
  {
    icon: '📝',
    text: 'Только изменения',
    value: 'system'
  }
])
const isAll = ref(false)
const isOpenReload = ref(false)
const formData = reactive({
  status: 3,
  comment: ''
})

// Computed
const currentComponent = computed(
  () => currentTab.value && defineAsyncComponent(() => import(`./${currentTab.value}/Body.vue`))
)
const isResponsible = computed(() => store.getters?.profile?.id === order.value?.responsible?.id)
const isSuperLawyer = computed(() => store.getters?.profile?.lawyer_role === 'Senior Lawyer')
const isHasEdit = computed(() => {
  switch (order.value?.status?.uid) {
    case 'canceled':
      return false

    case 'completed':
      return false

    case 'draft':
      return false

    case 'deleted':
      return false

    default:
      return true
  }
})

// Watch
watch(
  () => order.value.status,
  (val) => (currentTab.value = val.uid !== 'in-approval' ? 'history' : 'agreement')
)

// Created
currentTab.value = order.value.status.uid !== 'in-approval' ? 'history' : 'agreement'

// Methods
function getRows() {
  sidebarHistory.value?.getRows()
}
async function reloadAgreement() {
  if (isInvalidForm('form-reload')) return
  emits('globaLoad', true)
  isOpenReload.value = false

  try {
    await agreements.comment(order.value.id, formData)
    await store.dispatch('orders/GET_ITEM', order.value.id)
    getRows()
    formData.comment = ''
  } finally {
    emits('globaLoad', false)
  }
}
function setFilter(filter) {
  currentFilterItem.value = { ...filter }
  currentFilter.value = filter.value
  filterDropDown.value?.closeAction()
}

// Expose
defineExpose({
  getRows
})
</script>

<style lang="scss">
.sidebar {
  width: 550px;
  height: 100%;
  border-left: var(--b1);
  background-color: var(--form);

  &__head {
    background-color: var(--box);
    border-bottom: var(--b1);
    height: 50px;

    &-search-label {
      height: 100%;
      width: 30px;
    }
  }

  &__gobottom {
    left: 20px;
    bottom: 10px;
    z-index: 100;
  }
}
</style>
