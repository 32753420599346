<template>
  <!-- Reload agree -->
  <ModalEmpty v-if="isOpenReload" @close="isOpenReload = false">
    <form id="form-reload" class="modal-reload grid ggap-20 p-30" @submit.prevent="reloadAgreement">
      <h1 class="title">Опишите причину возврата заявки на рассмотрение</h1>
      <FormTextarea label="" placeholder="Описание причины" rows="5" required v-model="formData.comment" />
      <div class="flex ai-c jc-sb">
        <button class="btn cancel" type="button" @click.prevent="isOpenReload = false">Отмена</button>
        <button class="btn primary" type="submit">Вернуть на рассмотрение</button>
      </div>
    </form>
  </ModalEmpty>

  <!-- Cancel status -->
  <ModalEmpty v-if="isOpenModalCancel" @close="isOpenModalCancel = false">
    <form id="form-cancel" class="modal-cancel grid ggap-20 p-30" @submit.prevent="saveCancelAction('form-cancel')">
      <h1 class="title">Опишите причину отклонения</h1>
      <FormEditor
        label=""
        placeholder="Описание причины отклонения, например: не достаточно информации..."
        class="big"
        required
        v-model="reasonMessage"
      />
      <div class="flex ai-c jc-sb">
        <button class="btn cancel" type="button" @click.prevent="isOpenModalCancel = false">Отмена</button>
        <button class="btn primary" type="submit">Отправить комментарий, и отклонить заявку</button>
      </div>
    </form>
  </ModalEmpty>

  <!-- No final docs -->
  <ModalEmpty v-if="isOpenModalMessage" @close="isOpenModalMessage = false">
    <div class="w-400 grid">
      <div class="p-30 flex ai-c jc-c fd-c ta-center ggap-10">
        <BaseIcon class="ic-30 red" icon="alert-triangle" />
        <h1 class="title">Надо выбрать финальные документы</h1>
        <div class="t-grey-dark fs-16">
          Вы не выбрали ни одного финального документа, пожалуйста загрузите их и затем выберите как финальные!
        </div>
      </div>
      <hr class="m-0" />
      <div class="grid ggap-10 p-30">
        <h3 class="title">Инструкция:</h3>
        <p class="m-0">1) Загрузите документы в историю заявки</p>
        <p class="m-0">2) Выберите эти документы как финальные</p>
      </div>
    </div>
  </ModalEmpty>

  <!-- No agreementers -->
  <ModalEmpty v-if="isOpenModalMessageAgree" @close="isOpenModalMessageAgree = false">
    <div class="w-400 grid">
      <div class="p-30 flex ai-c jc-c fd-c ta-center ggap-10">
        <BaseIcon class="ic-30 red" icon="alert-triangle" />
        <h1 class="title">Нет участников согласования</h1>
        <div class="t-grey-dark fs-16">
          Вы не выбрали ни одного участника согласования, без них нельзя перейти на согласование!
        </div>
      </div>
      <hr class="m-0" />
      <div class="grid ggap-10 p-30">
        <h3 class="title">Инструкция:</h3>
        <p class="m-0">1) Нажмите на кнопку <b>Редактировать участников</b></p>
        <p class="m-0">2) Выберите таб Согласующие и затем выберите участников</p>
      </div>
    </div>
  </ModalEmpty>

  <!-- View -->
  <ModalFull :content-class="{ sm: isNew }" @close="closeAction">
    <!-- Head -->
    <template #head>
      <div class="flex ai-c ggap-10 jc-sb">
        <!-- Head info -->
        <div class="flex ai-c ggap-10">
          <h1 class="title">Заявка №{{ route.params && route.params.id }}</h1>

          <template v-if="item">
            <!-- Result title -->
            <template v-if="isCancel || isTowork">
              <span>-</span>
              <h1 class="title" :class="isCancel ? 't-red' : 't-green'">
                {{ isCancel ? 'Отклоняем' : 'Берем в работу' }}
              </h1>
            </template>

            <!-- Status -->
            <small
              v-if="item"
              class="marker"
              :style="{
                color: item.status && item.status.text_color,
                backgroundColor: item.status && item.status.background_color
              }"
            >
              {{ (item.status && item.status.name) || '&mdash;' }}
            </small>
          </template>
        </div>

        <!-- Head actions -->
        <div v-if="item" class="flex ai-c ggap-10">
          <RouterLink v-if="isHasEdit && isAuthor" :to="'/orders/edit/' + item.id" class="btn grey sm">
            <BaseIcon class="ic-20 primary" icon="edit" />
            Редактировать
          </RouterLink>
          <RouterLink
            v-if="(isLawyer || isSuperLawyer) && (item.status.uid === 'in-watch' || item.status.uid === 'in-work')"
            :to="'/orders/edit/' + item.id"
            class="btn grey sm"
          >
            <BaseIcon class="ic-20 primary" icon="edit" />
            Редактировать участников
          </RouterLink>
          <template v-if="item && !isNew">
            <RouterLink
              v-if="
                (isLawyer || isSuperLawyer || isBoss || isInitiator || isAgreementers || isReviewers) &&
                (item.status.uid === 'canceled' || item.status.uid === 'completed')
              "
              class="btn primary-light sm pl-10 pr-10"
              :to="'/orders/print/' + item.id"
            >
              <BaseIcon class="ic-16 primary" icon="print" />
              Распечатать лист согласования
            </RouterLink>
            <button class="btn grey sm" @click="deleteAction" v-if="isAuthor || isLawyer || isSuperLawyer">
              <BaseIcon class="ic-16 red" icon="delete" />
              Удалить заявку
            </button>

            <template v-if="isResponsible || isSuperLawyer">
              <button v-if="item.status.uid === 'in-work'" class="btn primary sm" @click="setStatusAction(5)">
                Отправить на рассмотрение
                <BaseIcon class="ic-20 white" icon="arrow-right" />
              </button>
              <button v-if="item.status.uid === 'in-watch'" class="btn primary sm" @click="setStatusAction(6)">
                Отправить на согласование
                <BaseIcon class="ic-20 white" icon="arrow-right" />
              </button>
              <button
                class="btn green sm"
                v-if="item?.status?.uid === 'doc-archive-waiting'"
                @click="setStatusAction(4)"
              >
                <BaseIcon class="ic-20 white" icon="check-circle" />
                Сдан в архив
              </button>
              <template v-if="item.status.uid === 'in-approval'">
                <button class="btn primary-light sm" @click="isOpenReload = !isOpenReload">
                  <BaseIcon class="ic-20 primary" icon="arrow-left" />
                  Вернуть на рассмотрение
                </button>
                <button
                  class="btn green sm"
                  @click="setStatusAction(4)"
                  v-if="!(item?.type === 'mc_agreement' && item?.status?.uid === 'in-approval')"
                >
                  <BaseIcon class="ic-20 white" icon="check-circle" />
                  Завершить заявку
                </button>
              </template>

              <!-- Statuses -->
              <BaseDropDown
                direction="rtl"
                btn-class="btn grey sm fw-b pl-10 pr-10"
                btn-active-class="active"
                ref="statusDropDown"
              >
                <template #btn>
                  <BaseIcon class="ic-20 black" icon="more-horizontal" />
                </template>
                <template #body>
                  <!-- Super lawyer -->
                  <div v-if="isSuperLawyer" @click.stop class="statuses-drop-down grid ggap-5 p-5">
                    <template v-for="status of statusesCustom" :key="status.id">
                      <button
                        v-if="status.uid !== 'deleted'"
                        class="btn transparent-grey sm pl-10"
                        :class="{ active: item.status.id === status.id }"
                        @click="setStatusAction(status.id)"
                      >
                        <div
                          class="statuses-drop-down__dot bg-grey-dark flex ai-c jc-c"
                          :style="{ backgroundColor: status.background_color }"
                        />
                        {{ status.name }}
                      </button>
                    </template>
                    <hr class="m-0" />
                    <template v-for="status of statusesStatic" :key="status.id">
                      <button
                        v-if="status.uid !== 'deleted'"
                        class="btn transparent-grey sm pl-10"
                        :class="{ active: item.status.id === status.id }"
                        @click="setStatusAction(status.id)"
                      >
                        <div
                          class="statuses-drop-down__dot bg-grey-dark flex ai-c jc-c"
                          :style="{ backgroundColor: status.background_color }"
                        />
                        {{ status.name }}
                      </button>
                    </template>
                    <hr class="m-0" />
                  </div>
                  <!-- Not super -->
                  <div v-else @click.stop class="statuses-drop-down grid ggap-5 p-5">
                    <button class="btn transparent-grey sm pl-10 pr-10" @click="setStatusAction(3)">
                      <BaseIcon class="ic-16 red" icon="close-circle" />
                      Отклонить
                    </button>
                    <button class="btn transparent-grey sm pl-10 pr-10" @click="setStatusAction(2)">
                      <BaseIcon class="ic-16 yellow" icon="history" />
                      Ожидаем
                    </button>
                    <button class="btn transparent-grey sm pl-10 pr-10" @click="setStatusAction(4)">
                      <BaseIcon class="ic-16 green" icon="check-circle" />
                      Завершить
                    </button>
                  </div>
                </template>
              </BaseDropDown>
            </template>
          </template>
        </div>
      </div>
    </template>

    <!-- Body -->
    <template #default>
      <div class="order grid" :class="{ 'gtc-1-auto': !isNew }">
        <BaseLoad v-if="isLoad" class="white z99999" />

        <!-- Has item -->
        <template v-if="item">
          <!-- Left -->
          <div class="order__left overflow-a h-100" id="print-content">
            <div class="order__left-content" :class="{ hide: isCollapsed, bordered: isCancel || isTowork }">
              <!-- Section Base -->
              <section class="p-20 grid gtc-2 ggap-20">
                <ItemRead label="Тип заявки">
                  <div>{{ orderType }}</div>
                </ItemRead>
                <ItemRead :label="Number(item.contract_price) ? 'Стоимость договора' : 'Оборот за 1 год'">
                  <div class="t-upper flex ai-c ggap-5">
                    {{ orderSumm }}
                    <!-- <BaseIcon class="ic-18 black" :icon="item.currency ? item.currency : 'kzt'" /> -->
                    <b>{{ item.currency ? item.currency : 'kzt' }}</b>
                  </div>
                </ItemRead>
              </section>

              <!-- Section Special conditions & Comment -->
              <section class="p-20 grid ggap-20">
                <ItemRead label="Особые условия">
                  <div
                    v-if="item.special_conditions"
                    class="editor-data box p-15 pt-10 pb-10"
                    v-html="DOMPurify.sanitize(item.special_conditions)"
                  />
                  <template v-else>&mdash;</template>
                </ItemRead>
                <ItemRead v-if="item.comments" label="Комментарий инициатора">
                  <div
                    v-if="item.comments"
                    class="editor-data box p-15 pt-10 pb-10 break-space"
                    v-html="DOMPurify.sanitize(item.comments)"
                  />
                  <template v-else>&mdash;</template>
                </ItemRead>
              </section>
              <section class="p-20 grid ggap-20 gtc-2" v-if="item?.type === 'request_mc'">
                <ItemRead label="Номер документа">
                  <div>{{ item?.request_mc?.doc_number || '' }}</div>
                </ItemRead>
                <ItemRead label="Уникальный идентификатор">
                  <div>{{ item?.request_mc?.identifier || '' }}</div>
                </ItemRead>
                <ItemRead label="Договор">
                  <div>{{ item?.request_mc?.contract_number || '' }}</div>
                </ItemRead>
                <ItemRead label="Дата завершения">
                  <div>
                    {{
                      item?.request_mc?.deadline_date
                        ? moment(item?.request_mc?.deadline_date, 'YYYY-MM-DD').format('DD.MM.YYYY')
                        : ''
                    }}
                  </div>
                </ItemRead>
              </section>

              <!-- Section Documents -->
              <section class="p-20 grid ggap-20">
                <ItemRead label="Прикрепленные файлы">
                  <div v-if="item.documents && item.documents.length" class="box grid overflow-h">
                    <ItemFile
                      v-for="file of item.documents"
                      :key="file.id"
                      :item="file"
                      :isSelectDisabled="item.status.id !== 5"
                      :btns="{ download: isDownload(file.id), select: item.status.id === 5, remove: false }"
                      :orderStatus="item.status"
                    />
                  </div>
                  <BaseAlert v-else type="yellow"> Прикрепленные файлы не найдены </BaseAlert>
                </ItemRead>

                <ItemRead v-if="item.finalDocuments && item.finalDocuments.length" label="Финальные документы">
                  <div class="box grid overflow-h">
                    <ItemFile
                      v-for="file of item.finalDocuments"
                      :key="file.id"
                      :item="file"
                      :finalDocs="item.finalDocuments"
                      :isSelectDisabled="item.status.id !== 5"
                      :btns="{ download: true, select: item.status.id === 5, remove: false }"
                      :orderStatus="item.status"
                    />
                  </div>
                </ItemRead>
              </section>

              <!-- Section Counterparty & Project -->
              <section class="p-20 grid gtc-2 ggap-20">
                <ItemRead label="Проект">
                  <div v-if="item.project" class="grid ggap-5 box p-10">
                    <div class="grid gtc-1-auto ai-c ggap-10">
                      <strong class="cut">{{ item.project.project }}</strong>
                      <a href="#" @click.prevent="isOpenProject = !isOpenProject">реквизиты</a>
                    </div>
                    <strong class="fs-12 t-grey-dark">БИН: {{ item.project.bin }}</strong>
                  </div>
                  <div v-else>&mdash;</div>

                  <!-- Info -->
                  <div v-if="isOpenProject" class="box p-10 grid ggap-10">
                    <table>
                      <tr>
                        <td>Наименование:</td>
                        <td>{{ item.project.llp }}</td>
                      </tr>
                      <tr>
                        <td>БИН:</td>
                        <td>{{ item.project.bin }}</td>
                      </tr>
                      <tr>
                        <td>Банк:</td>
                        <td>{{ item.project.bank }}</td>
                      </tr>
                      <tr>
                        <td>БИК:</td>
                        <td>{{ item.project.bik }}</td>
                      </tr>
                    </table>
                  </div>
                </ItemRead>

                <ItemRead label="Контрагент">
                  <div v-if="item.counterparty_name && item.counterparty_bin" class="grid ggap-5 box p-10">
                    <div class="grid gtc-1-auto ai-c ggap-10">
                      <div class="grid gtc-1-auto ggap-5">
                        <strong class="cut">{{ item.counterparty_name }}</strong>
                        <small v-if="!item.counterparty" class="marker red"> Удалено </small>
                      </div>
                      <a href="#" @click.prevent="isOpenCounterparty = !isOpenCounterparty">реквизиты</a>
                    </div>
                    <strong class="fs-12 t-grey-dark">БИН: {{ item.counterparty_bin }}</strong>
                  </div>
                  <div v-else>&mdash;</div>

                  <!-- Info -->
                  <div
                    v-if="isOpenCounterparty"
                    class="box p-10 editor-data"
                    v-html="DOMPurify.sanitize(item.counterparty_info) || 'Нет реквизитов'"
                  />
                </ItemRead>
              </section>

              <!-- Section Author & Responsible -->
              <section class="p-20 grid gtc-2 ggap-20">
                <ItemRead label="Инициатор">
                  <div v-if="item.author" class="box p-5 grid gtc-auto-1 ggap-10 ai-c">
                    <div class="cover ava-50">
                      <img :src="getAvatar(item.author.avatar)" />
                    </div>
                    <div class="flex ai-c ggap-20">
                      <div class="grid ggap-5">
                        <a :href="profileLink + item.author.id">{{ cutFullName(item.author.fullname) }}</a>
                        <small class="t-grey-dark">{{ item.author.specialization || 'Нет должности' }}</small>
                      </div>
                      <button class="btn sm pl-10 pr-10 ml-auto" @click.prevent="isContactsAuthor = !isContactsAuthor">
                        контакты
                      </button>
                    </div>
                  </div>

                  <!-- Contacts -->
                  <div v-if="isContactsAuthor" class="contacts flex fw ggap-5">
                    <template
                      v-if="
                        (item.author.contacts && item.author.contacts.length) ||
                        (item.contact_information && Object.keys(item.contact_information).length)
                      "
                    >
                      <a
                        v-for="contact of item.author.contacts"
                        :key="contact.id"
                        :href="getLink({ key: contact.type, value: contact.value })"
                        class="contacts__item box flex ai-c ggap-5 pt-5 pb-5 pl-10 pr-10"
                      >
                        <BaseIcon class="ic-14 grey" :icon="contact.type" />
                        {{ contact.value }}
                      </a>
                      <a
                        v-for="(value, key) in item.contact_information"
                        :key="key"
                        :href="getLink({ key, value })"
                        class="contacts__item box flex ai-c ggap-5 pt-5 pb-5 pl-10 pr-10"
                      >
                        <BaseIcon class="ic-14 primary" :icon="key" />
                        {{ value }}
                      </a>
                    </template>

                    <BaseAlert v-else class="w-100" type="yellow">Контакты не найдены</BaseAlert>
                  </div>
                </ItemRead>

                <ItemRead label="Исполнитель">
                  <div v-if="item.responsible" class="box p-5 grid gtc-auto-1 ggap-10 ai-c">
                    <div class="cover ava-50">
                      <img :src="getAvatar(item.responsible.avatar)" />
                    </div>
                    <div class="flex ai-c ggap-20">
                      <div class="grid ggap-5">
                        <a :href="profileLink + item.responsible.id">
                          {{ cutFullName(item.responsible.fullname) }}
                        </a>
                        <small class="t-grey-dark">{{ item.responsible.specialization || 'Нет должности' }}</small>
                      </div>
                      <button
                        class="btn sm pl-10 pr-10 ml-auto"
                        @click.prevent="isContactsResponsible = !isContactsResponsible"
                      >
                        контакты
                      </button>
                    </div>
                  </div>
                  <template v-else>&mdash;</template>

                  <!-- Contacts -->
                  <div v-if="isContactsResponsible" class="contacts flex fw ggap-5">
                    <template v-if="item.responsible.contacts && item.responsible.contacts.length">
                      <a
                        v-for="contact of item.responsible.contacts"
                        :key="contact.id"
                        :href="getLink({ key: contact.type, value: contact.value })"
                        class="contacts__item box flex ai-c ggap-5 pt-5 pb-5 pl-10 pr-10"
                      >
                        <BaseIcon class="ic-14 black" :icon="contact.type" />
                        {{ contact.value }}
                      </a>
                    </template>
                    <BaseAlert v-else class="w-100" type="yellow">Контакты не найдены</BaseAlert>
                  </div>
                </ItemRead>
              </section>

              <!-- Section Peoples -->
              <section v-if="isPeoples" class="p-20 grid ggap-20">
                <Peoples
                  v-if="peoplesSingle && !isLoad && peoplesDepartments && peoplesList"
                  :isRead="true"
                  :isFree="isFree"
                  :objUsers="peoplesSingle"
                  :freeUsers="peoplesList"
                  :departmentUsers="peoplesDepartments"
                />
              </section>

              <!-- Section Dates -->
              <section class="p-20 grid gtc-3 ggap-20 b-t">
                <ItemRead label="Дата создания">
                  <div class="flex ai-c ggap-10">
                    <!-- <BaseIcon class="ic-14 black" icon="calendar" /> -->
                    <!-- 20 февраля 2022 в 16:13 -->
                    {{ formatRuDateTime(item.created_at) }}
                  </div>
                </ItemRead>

                <ItemRead label="Дата изменения">
                  <div class="flex ai-c ggap-10">
                    <!-- <BaseIcon class="ic-14 black" icon="calendar" /> -->
                    {{ formatRuDateTime(item.updated) }}
                  </div>
                </ItemRead>

                <ItemRead label="Взята в работу">
                  <div v-if="item.got_for_work_at" class="flex ai-c ggap-10">
                    <BaseIcon class="ic-14 black" icon="calendar" />
                    {{ formatRuDateTime(item.got_for_work_at) }}
                  </div>
                  <template v-else>&mdash;</template>
                </ItemRead>
              </section>
            </div>

            <!-- Result -->
            <form id="form-result" v-if="isCancel || isTowork" class="order__left-result p-20">
              <button
                type="button"
                class="order__left-result-btn btn sm outline"
                @click.prevent.stop="isCollapsed = !isCollapsed"
              >
                {{ isCollapsed ? 'Развернуть' : 'Свернуть' }}
              </button>

              <!-- Cancel -->
              <template v-if="isCancel">
                <h3 class="title mb-10">Опишите причину отклонения</h3>
                <FormEditor
                  label=""
                  placeholder="Описание причины отклонения, например: не достаточно информации..."
                  class="big"
                  required
                  v-model="reasonMessage"
                />
              </template>

              <!-- To work -->
              <template v-if="isTowork">
                <h3 class="title mb-10">Выберите исполнителя и других участников</h3>
                <Peoples
                  v-if="peoplesSingle && !isLoad && peoplesDepartments && peoplesList"
                  :isRead="false"
                  :isFree="isFree"
                  :objUsers="peoplesSingle"
                  :freeUsers="peoplesList"
                  :departmentUsers="peoplesDepartments"
                />
              </template>
            </form>
          </div>

          <!-- Right -->
          <div v-if="!isNew" class="order__right overflow-h">
            <Sidebar :order="item" ref="sidebarRef" id="print-sidebar" @globaLoad="isLoad = $event" />
          </div>

          <!-- Foot -->
          <div v-if="item && isNew && (isLawyer || isSuperLawyer)" class="order__foot pl-20 pr-20 pt-10 pb-10 bt">
            <!-- Cancel -->
            <div v-if="isCancel" class="flex ai-c jc-sb">
              <button class="btn grey pl-10" @click="goBack">
                <BaseIcon class="ic-20 primary" icon="arrow-left" />
                Назад
              </button>
              <button class="btn red" @click="saveCancelAction('form-result')">Сохранить и отклонить заявку</button>
            </div>

            <!-- To work -->
            <div v-else-if="isTowork" class="flex ai-c jc-sb">
              <button class="btn grey pl-10" @click="goBack">
                <BaseIcon class="ic-20 primary" icon="arrow-left" />
                Назад
              </button>
              <button class="btn green" @click="saveToworkAction('form-result')">Сохранить и взять в работу</button>
            </div>

            <!-- Draft -->
            <div v-else-if="isAuthor && item.status.id === 7" class="grid">
              <div v-if="isDeleteAction" class="flex fd-c ai-c jc-c">
                <h3 class="title mb-10">Вы действительно хотите удалить эту заявку?</h3>
                <div class="flex ai-c jc-c ggap-10">
                  <button @click.prevent.stop="isDeleteAction = false" class="btn cancel">Нет, отмена</button>
                  <button @click.prevent.stop="setStatusAction(8)" class="btn red">
                    <BaseIcon class="ic-20 white" icon="delete" />
                    Да, удалить заявку
                  </button>
                </div>
              </div>

              <div v-else class="flex ai-c jc-sb">
                <div class="flex ai-c ggap-10">
                  <button class="btn grey pl-10" @click="isDeleteAction = !isDeleteAction">
                    <BaseIcon class="ic-20 red" icon="delete" />
                    Удалить
                  </button>
                  <RouterLink :to="'/orders/edit/' + item.id" class="btn grey">
                    <BaseIcon class="ic-20 primary" icon="edit" />
                    Редактировать
                  </RouterLink>
                </div>
                <button class="btn primary" @click="setStatusAction(1)">Отправить заявку Юристам</button>
              </div>
            </div>

            <!-- Base -->
            <div v-else class="flex ai-c jc-sb">
              <button class="btn red" @click="cancelAction">
                <BaseIcon class="ic-20 white" icon="close-circle" />
                Отклонить
              </button>
              <button class="btn green" @click="toworkAction">
                <BaseIcon class="ic-20 white" icon="check-circle" />
                Взять в работу
              </button>
            </div>
          </div>
        </template>
        <div v-else class="flex fd-c ai-c jc-c ggap-10">
          <BaseIcon class="ic-30 red" icon="alert-triangle" />
          <h2 class="title t-red">Ошибка загрузки</h2>
          <div>Заявки с таким номером не существует</div>
        </div>
      </div>
    </template>
  </ModalFull>
</template>

<script setup>
import { cloneDeep, isEqual } from 'lodash'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { computed, ref, reactive, watch, onMounted, inject } from 'vue'
import {
  BaseLoad,
  BaseDropDown,
  ModalEmpty,
  ModalFull,
  BaseIcon,
  BaseAlert,
  ItemRead,
  ItemFile,
  FormEditor,
  FormTextarea,
  Peoples
} from '@/components'
import {
  isInvalidForm,
  $busEmit,
  preparingPeoplesList,
  preparingPeoplesSingle,
  preparingPeoplesDepartments,
  formatRuDateTime,
  cutFullName,
  getAvatar,
  disableAllBtns,
  linksBlank
} from '@/plugins'
import Sidebar from './components/sidebar/Index.vue'
import events from '@/api/modules/events'
import orders from '@/api/modules/orders'
import agreements from '@/api/modules/agreements'
import socketConnect from '@/plugins/socket-connect'
import moment from 'moment'
import DOMPurify from 'dompurify'

// Data
const router = useRouter()
const route = useRoute()
const store = useStore()
const profileLink = inject('profileLink')

// Data: boolean
const isLoad = ref(false)
const isOpenModalCancel = ref(false)
const isOpenModalMessage = ref(false)
const isOpenModalMessageAgree = ref(false)
const isCancel = ref(false)
const isTowork = ref(false)
const isCollapsed = ref(false)
const isContactsAuthor = ref(false)
const isContactsResponsible = ref(false)
const isOpenCounterparty = ref(false)
const isOpenProject = ref(false)
const isDeleteAction = ref(false)
const isFree = reactive({
  reviewers: false,
  agreementers: false,
  watchers: false,
  final_boss: true
})

// const item = ref()

const peoplesList = ref(null)
const peoplesSingle = ref(null)
const peoplesDepartments = ref(null)

const peoplesListNochanged = ref(null)
const peoplesSingleNochanged = ref(null)
const peoplesDepartmentsNochanged = ref(null)

const reasonMessage = ref('')
const sidebarRef = ref()
const statusDropDown = ref()

const isOpenReload = ref(false)
const formData = reactive({
  status: 3,
  comment: ''
})

// Computed
const profile = computed(() => store.getters.profile)
// const rows = computed(() => store.getters['orders/rows'] || [])
const item = computed(() => store.getters['orders/item'])
// const item = computed(() => {
//   let res = null
//   const currentId = Number(route.params.id)
//   const item = store.getters['orders/item']
//   if (item?.id === currentId) {
//     res = item
//   } else if (rows.value?.length) {
//     res = rows.value.find((el) => Number(el.id) === currentId)
//   }
//   return res
// })
const statuses = computed(() => store.getters['helpers/statuses'] || [])
const statusesStatic = computed(() => statuses.value?.filter((el) => el.is_static))
const statusesCustom = computed(() => statuses.value?.filter((el) => !el.is_static))
const agreementersCount = computed(() => {
  let res = 0
  if (item.value?.agreement_departments?.length) {
    for (let i = 0; i < item.value.agreement_departments.length; i++) {
      const element = item.value.agreement_departments[i]
      res = res + element?.users?.length + element?.manual_users?.length
    }
  }
  return res
})

const isPeoples = computed(() => {
  const reviewers = peoplesList.value?.reviewers?.length || peoplesDepartments.value?.reviewers?.length
  const agreementers = peoplesList.value?.agreementers?.length || peoplesDepartments.value?.agreementers?.length
  const watchers = peoplesList.value?.watchers?.length || peoplesDepartments.value?.watchers?.length
  const final_boss = !!peoplesSingle.value?.final_boss?.id

  return reviewers || agreementers || watchers || final_boss
})

const optionsOrderType = [
  {
    text: 'Создать документ',
    value: 'create'
  },
  {
    text: 'Согласовать документ',
    value: 'matching'
  },
  {
    text: 'Согласование МС',
    value: 'mc_agreement'
  }
]

const orderType = computed(() => {
  return optionsOrderType.find((el) => el.value === item.value?.type)?.text
})
const orderSumm = computed(() => {
  return Number(item.value?.contract_price)
    ? Number(item.value.contract_price).toLocaleString('ru-RU')
    : Number(item.value?.annual_turnover).toLocaleString('ru-RU')
})

const isNew = computed(() => item.value?.status?.uid === 'new')
const isResponsible = computed(() => profile.value?.id === item.value?.responsible?.id)
const isSuperLawyer = computed(() => profile.value?.lawyer_role === 'Senior Lawyer')
const isLawyer = computed(() => profile.value?.lawyer_role === 'Lawyer')
const isInitiator = computed(() => item.value?.author?.id === profile?.value?.id)
const isReviewers = computed(() => item.value.reviewers.filter((item) => item.id === profile.value.id)?.length)
const isAgreementers = computed(() => item.value.agreementers.filter((item) => item.id === profile.value.id)?.length)
const isBoss = computed(() => item.value?.final_boss?.id === profile.value?.id)
const isAuthor = computed(() => item.value?.author?.id === profile.value?.id)
const isHasEdit = computed(() => {
  switch (item.value?.status?.uid) {
    case 'new':
      return true

    // case 'canceled':
    //   return true

    // case 'completed':
    //   return true

    case 'draft':
      return true

    case 'deleted':
      return true

    default:
      return false
  }
})

// Watch
watch(isLoad, (val) => {
  disableAllBtns('.modal', val)
})

// Created
getItem()
wsConntect()
store.dispatch('helpers/GET_STATUSES')

// Mounted
onMounted(() => {
  linksBlank()
})

// Methods
function closeAction() {
  router.replace('/orders')
}
function goBack() {
  isCancel.value = false
  isTowork.value = false
  isCollapsed.value = false
}
function isDownload(fileId) {
  const index = item.value.finalDocuments.findIndex((el) => el.id === fileId)
  if (index !== -1) return true
  else if (item.value?.status?.uid === 'completed') return isSuperLawyer.value || isLawyer.value
  else return true
}
async function getItem() {
  isLoad.value = true
  try {
    await store.dispatch('orders/GET_ITEM', route.params.id)
    setPeoplesData()
  } finally {
    isLoad.value = false
    store.dispatch('orders/GET_ROWS')
  }
}
async function setPeoplesData() {
  // Set peoples
  peoplesList.value = await preparingPeoplesList(item.value)
  peoplesSingle.value = await preparingPeoplesSingle(item.value)
  peoplesDepartments.value = await preparingPeoplesDepartments(item.value)

  // Set peoples dublicate
  peoplesListNochanged.value = cloneDeep(peoplesList.value)
  peoplesSingleNochanged.value = cloneDeep(peoplesSingle.value)
  peoplesDepartmentsNochanged.value = cloneDeep(peoplesDepartments.value)
}
function getLink({ key, value }) {
  switch (key) {
    case 'phone':
      return 'tel:' + value

    case 'email':
      return 'mailto:' + value

    case 'whatsapp':
      return 'https://wa.me/' + value

    case 'telegram':
      return 'tg://resolve?domain=' + value

    default:
      return value
  }
}

// Methods:if status new
function cancelAction() {
  isCancel.value = true
  isCollapsed.value = true
}
function toworkAction() {
  isTowork.value = true
  isCollapsed.value = true
}
function saveCancelAction(formId) {
  if (isInvalidForm(formId)) return

  isLoad.value = true
  isOpenModalCancel.value = false
  const payload = new FormData()
  const cleanedMessage = reasonMessage.value.split('<p>&nbsp;</p>').join('')
  payload.append('type', 'reject')
  payload.append('comment', cleanedMessage)

  events
    .comment(route.params.id, payload)
    .then(async () => {
      await orders.updateStatus(item.value.id, { status_id: 3 })
      await store.dispatch('orders/GET_ROWS')
      closeAction()
    })
    .finally(() => (isLoad.value = false))
}
async function saveToworkAction(formId) {
  if (isInvalidForm(formId)) return
  isLoad.value = true

  try {
    await allActions()
    await store.dispatch('orders/GET_ROWS')

    closeAction()
    $busEmit('setToast', {
      type: 'green',
      icon: 'check-circle',
      message: 'Заявка успешно взята в работу!'
    })
  } finally {
    isLoad.value = false
  }
}
async function allActions() {
  return Promise.all([
    await updateResponsible(),
    await updateAgreementers(),
    await updatePeoplesFree(),
    await updatePeoplesDepartments(),
    await orders.updateStatus(item.value.id, { status_id: 2 })
  ])
}

// Methods:change status
async function deleteAction() {
  isLoad.value = true
  try {
    statusDropDown.value?.closeAction()
    await orders.updateStatus(item.value.id, { status_id: 8 })
    await store.dispatch('orders/GET_ROWS')
    closeAction()
    $busEmit('setToast', {
      type: 'green',
      icon: 'check-circle',
      message: 'Заявка успешно удалена!'
    })
  } finally {
    isLoad.value = false
  }
}
async function setStatusAction(statusId, isNext = false) {
  statusDropDown.value?.closeAction()
  if (!isNext && statusId === 3) return (isOpenModalCancel.value = true)
  if (!isNext && statusId === 6) {
    const agreeLength = item.value?.agreementers?.length + agreementersCount.value
    if (!agreeLength) return (isOpenModalMessageAgree.value = true)
    if (!item.value.finalDocuments.length) return (isOpenModalMessage.value = true)
  }

  if (item.value.status.id !== statusId) {
    isLoad.value = true
    try {
      await orders.updateStatus(item.value.id, { status_id: statusId })
      await store.dispatch('orders/GET_ITEM', route.params.id)
      // sidebarRef.value?.getRows()
      setPeoplesData()
    } finally {
      isLoad.value = false
      store.dispatch('orders/GET_ROWS')
    }
  }
}
async function reloadAgreement() {
  if (isInvalidForm('form-reload')) return
  isLoad.value = true
  isOpenReload.value = false

  try {
    await agreements.comment(route.params.id, formData)
    await setStatusAction(5)
    getItem()
    formData.comment = ''
  } finally {
    isLoad.value = false
  }
}

// Methods:preparing
function preparingPayloadDepartments() {
  const payloadDepartments = {
    review_departments: [],
    watcher_departments: []
  }

  for (const key in isFree) {
    if (Object.hasOwnProperty.call(isFree, key)) {
      const keyDepartment = key === 'reviewers' ? 'review_departments' : 'watcher_departments'
      const currentDepartments = peoplesDepartments.value[key] || []
      const oldDepartments = peoplesDepartmentsNochanged.value[key] || []

      if (!isFree[key] && key !== 'final_boss' && key !== 'agreementers') {
        // ### Бежим по текущим отделам ###
        if (currentDepartments.length) {
          for (let i = 0; i < currentDepartments.length; i++) {
            const item = currentDepartments[i]
            const payload = {
              department_id: item.id,
              new_department_users: [],
              deletable_department_users: [],
              new_manual_users: [],
              deletable_manual_users: []
            }

            // Ищем старый отдел
            const oldItem = oldDepartments.find((el) => el.id === item.id)

            // Если нет старого отдела
            if (!oldItem) {
              payload.new_department_users = item.users.map((el) => el.id)
              payload.new_manual_users = item.manual_users.map((el) => el.id)
            }

            // Если есть новые кастомные юзеры
            else {
              const manualUsers = item.manual_users.map((el) => el.id)
              const oldManualUsers = oldItem.manual_users.map((el) => el.id)
              payload.new_manual_users = manualUsers.filter((el) => !oldManualUsers.includes(el))
              payload.deletable_manual_users = oldManualUsers.filter((el) => !manualUsers.includes(el))
            }

            if (
              payload.new_department_users.length ||
              payload.deletable_department_users.length ||
              payload.new_manual_users.length ||
              payload.deletable_manual_users.length
            ) {
              payloadDepartments[keyDepartment].push(payload)
            }
          }
        }

        // ### Бежим по старым отделам и проверяем удалили ли что нибудь ###
        const cdID = currentDepartments.map((el) => el.id)
        const odID = oldDepartments.map((el) => el.id)
        const hasDeleted = odID.filter((el) => !cdID.includes(el))
        if (hasDeleted.length) {
          for (let i = 0; i < hasDeleted.length; i++) {
            const id = hasDeleted[i]
            const payload = {
              department_id: id,
              new_department_users: [],
              deletable_department_users: [],
              new_manual_users: [],
              deletable_manual_users: []
            }

            // Ищем старый отдел
            const oldItem = oldDepartments.find((el) => el.id === id)

            // Вставляем удаленных юзеров
            if (oldItem) {
              payload.deletable_department_users = oldItem.users.map((el) => el.id)
              payload.deletable_manual_users = oldItem.manual_users.map((el) => el.id)
            }

            payloadDepartments[keyDepartment].push(payload)
          }
        }
      }

      // Проверяем надо ли удалить старые записи
      else if (isFree[key] && key !== 'final_boss' && key !== 'agreementers' && oldDepartments.length) {
        for (let i = 0; i < oldDepartments.length; i++) {
          const item = oldDepartments[i]
          const payload = {
            department_id: item.id,
            new_department_users: [],
            deletable_department_users: item.users.map((el) => el.id),
            new_manual_users: [],
            deletable_manual_users: item.manual_users.map((el) => el.id)
          }
          payloadDepartments[keyDepartment].push(payload)
        }
      }
    }
  }

  return payloadDepartments
}
function preparingPayloadFree() {
  const payloadFree = {
    new_reviewers: [],
    deletable_reviewers: [],
    new_watchers: [],
    deletable_watchers: []
  }

  for (const key in isFree) {
    if (Object.hasOwnProperty.call(isFree, key)) {
      const currentFree = peoplesList.value[key] || []
      const oldFree = peoplesListNochanged.value[key] || []

      // Если свободный выбор
      if (isFree[key] && key !== 'final_boss' && key !== 'agreementers') {
        // ### Бежим по текущим юзерам ###
        if (currentFree.length) {
          // New
          for (let i = 0; i < currentFree.length; i++) {
            const item = currentFree[i]
            const isNope = oldFree.findIndex((el) => el.id === item.id) === -1
            if (isNope) payloadFree['new_' + key].push(item.id)
          }
        }

        // ### Бежим по старым юзерам и проверяем удалили ли что нибудь ###
        if (oldFree.length) {
          // Old
          for (let i = 0; i < oldFree.length; i++) {
            const item = oldFree[i]
            const isNope = currentFree.findIndex((el) => el.id === item.id) === -1
            if (isNope) payloadFree['deletable_' + key].push(item.id)
          }
        }
      }

      // Проверяем надо ли удалить старые записи
      else if (!isFree[key] && key !== 'final_boss' && key !== 'agreementers') {
        for (let i = 0; i < oldFree.length; i++) {
          const item = oldFree[i]
          payloadFree['deletable_' + key].push(item.id)
        }
      }
    }
  }

  return payloadFree
}

// Methods:peoples
function updatePeoplesFree() {
  const payload = preparingPayloadFree()

  // Final boss
  if (peoplesSingleNochanged.value.final_boss?.id !== peoplesSingle.value.final_boss?.id) {
    payload.final_boss = peoplesSingle.value.final_boss?.id
  }

  // Clear payload
  if (!payload.new_reviewers?.length) delete payload.new_reviewers
  if (!payload.deletable_reviewers?.length) delete payload.deletable_reviewers
  if (!payload.new_watchers?.length) delete payload.new_watchers
  if (!payload.deletable_watchers?.length) delete payload.deletable_watchers

  // Send promise
  return new Promise((resolve) => {
    if (Object.keys(payload).length) {
      resolve(orders.updatePeoples(route.params.id, payload))
    } else {
      resolve()
    }
  })
}
function updatePeoplesDepartments() {
  const payload = preparingPayloadDepartments()

  // Clear payload
  if (!payload.review_departments?.length) delete payload.review_departments
  if (!payload.watcher_departments?.length) delete payload.watcher_departments

  // Send promise
  return new Promise((resolve) => {
    if (Object.keys(payload).length) {
      resolve(orders.updatePeoplesDepartments(route.params.id, payload))
    } else {
      resolve()
    }
  })
}
function updateAgreementers() {
  const isChangedAgree = []
  const oldPL = peoplesListNochanged.value?.agreementers?.map((el) => el.id)
  const newPL = peoplesList.value?.agreementers?.map((el) => el.id)
  const oldDP = peoplesDepartmentsNochanged.value?.agreementers
  const newDP = peoplesDepartments.value?.agreementers

  if (oldPL.length || newPL.length) isChangedAgree.push(!isEqual(newPL, oldPL))
  if (oldDP.length || newDP.length) isChangedAgree.push(!isEqual(newDP, oldDP))
  isChangedAgree.push(isFree.agreementers && oldDP.length > 0)

  const payload = {
    agreementers: [],
    agreement_departments: [],
    deletable_departments: []
  }

  if (isChangedAgree.some((el) => el)) {
    const oldDepartsFlat = oldDP.map((el) => el.id)

    // Free
    if (isFree.agreementers) {
      for (let i = 0; i < peoplesList.value.agreementers.length; i++) {
        const element = peoplesList.value.agreementers[i]
        payload.agreementers.push({
          id: element.id,
          index: i
        })
      }

      payload.deletable_departments = oldDepartsFlat
    }

    // Departments
    if (!isFree.agreementers && !isEqual(newDP, oldDP)) {
      for (let i = 0; i < peoplesDepartments.value.agreementers.length; i++) {
        const element = peoplesDepartments.value.agreementers[i]
        payload.agreement_departments.push({
          department_id: element.id,
          department_index: i,
          manual_users: element.manual_users.map((el, index) => {
            return {
              id: el.id,
              index
            }
          })
        })
      }

      const curDeparts = newDP.map((el) => el.id)
      payload.deletable_departments = oldDepartsFlat?.length
        ? oldDepartsFlat.filter((el) => !curDeparts.includes(el))
        : []
    }
  }

  // Cleaning payload
  if (!payload.agreementers.length) delete payload.agreementers
  if (!payload.agreement_departments.length) delete payload.agreement_departments
  if (!payload.deletable_departments.length) delete payload.deletable_departments

  // Send promise
  return new Promise((resolve) => {
    // If has payload send request
    if (Object.keys(payload).length) {
      resolve(orders.updatePeoplesAgree(route.params.id, payload))
    } else {
      resolve()
    }
  })
}
function updateResponsible() {
  // Send promise
  return new Promise((resolve) => {
    if (peoplesSingleNochanged.value?.responsible?.id !== peoplesSingle.value?.responsible?.id) {
      resolve(orders.updateResponsible(route.params.id, { responsible_id: peoplesSingle.value.responsible?.id }))
    } else {
      resolve()
    }
  })
}

// Methods:websocket
function wsConntect() {
  const name = `private-user.${profile.value?.id}`
  socketConnect.channel(name).listen('.requestHistoryUpdated', async (data) => {
    if (route.params.id && data?.request_id === Number(route.params.id)) {
      await store.dispatch('orders/GET_ITEM', route.params.id)
      setPeoplesData()
    }
  })
}
</script>

<style lang="scss" scoped>
.modal {
  &-final,
  &-cancel {
    width: 600px;
  }
}
.statuses-drop-down {
  max-height: calc(100vh - 200px);
  overflow: auto;

  &__dot {
    width: 10px;
    height: 10px;
    border-radius: 100px;

    & > span {
      display: block;
      width: 7px;
      height: 7px;
      border-radius: 100px;
    }
  }
}
.order {
  position: relative;
  max-height: calc(95vh - 50px);

  &__left {
    &-content {
      &.hide {
        height: 200px;
        overflow: hidden;
        position: relative;
        z-index: 1;

        &::before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          background-color: var(--form);
          opacity: 0.8;
          backdrop-filter: blur(10px);
          -webkit-backdrop-filter: blur(10px);
        }
      }

      &.bordered {
        border-bottom: var(--b1);
      }

      .editor-data {
        // background-color: var(--grey-l);
      }

      .contacts {
        &__item {
          text-decoration: none;
          color: var(--text);

          &:hover {
            background-color: var(--grey-l);
          }
        }
      }
    }

    &-result {
      position: relative;

      &-btn {
        position: absolute;
        top: -16px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
      }
    }
  }

  &__foot {
    border-top: var(--b1);
  }
}
</style>
